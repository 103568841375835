import { PUBLIC_API_URL } from 'utils/api_url'

export const allFord = [
    {
        id: 1036,
        name: '2025 BRONCO SPORT',
        image: require('../assets/images/vehicles/2025_Ford_BroncoSport_OuterBanks_AzureGray.png'),
        url: 'https://blueoval.events/2025_Bronco_Sport',
    },
    {
        id: 1035,
        name: '2025 MUSTANG GTD',
        image: require('../assets/images/vehicles/2025_Ford_Mustang_GTD_Logo.png'),
        url: 'https://eventlink.live/2025_Mustang_GDT',
    },
    {
        id: 202,
        name: 'BRONCO',
        image: require('../assets/images/vehicles/2024_Ford_Bronco_OuterBanks_Gray.png'),
        url: 'https://eventlink.live/Bronco_Info',
    },
    {
        id: 1033,
        name: 'BRONCO SPORT',
        image: require('../assets/images/vehicles/2024_Ford_BroncoSport_Badlands_Gray.png'),
        url: 'https://eventlink.live/2024_Bronco_Sport',
    },
    {
        id: 171,
        name: 'EDGE',
        image: require('../assets/images/vehicles/2024_Ford_Edge_ST_Silver.png'),
        url: 'https://eventlink.live/Edge_Info',
    },
    {
        id: 1034,
        name: 'ELECTRIC VEHICLES',
        image: require('../assets/images/vehicles/Ford_Electric_Jellybean.png'),
        url: 'https://eventlink.live/Electric_Vehicles_Info',
    },
    {
        id: 172,
        name: 'ESCAPE',
        image: require('../assets/images/vehicles/2024_Ford_Escape_Platinum_Silver.png'),
        url: 'https://eventlink.live/Escape_Info',
    },
    {
        id: 197,
        name: 'EXPEDITION',
        image: require('../assets/images/vehicles/2024_Ford_Expedition_Limited_Silver.png'),
        url: 'https://eventlink.live/2024_Expedition_Brochure',
    },
    {
        id: 174,
        name: 'EXPLORER',
        image: require('../assets/images/vehicles/2025_Ford_Explorer_Platinum.png'),
        url: 'https://eventlink.live/Explorer_Info',
    },
    {
        id: 195,
        name: 'F-150',
        image: require('../assets/images/vehicles/2024_Ford_F150_Platinum_Silver.png'),
        url: 'https://eventlink.live/F150_Info',
    },
    {
        id: 1013,
        name: 'F-150 LIGHTNING',
        image: require('../assets/images/vehicles/2024_Ford_F150_Lightning_Silver.png'),
        url: 'https://eventlink.live/F150_Lightning_Info',
    },
    {
        id: 1028,
        name: 'F-150 RAPTOR R',
        image: require('../assets/images/vehicles/2024_Ford_F150_Raptor_Gray.png'),
        url: 'https://eventlink.live/F-150-Raptor-R_Info',
    },
    {
        id: 193,
        name: 'FORD GT',
        image: require('../assets/images/vehicles/2023_Ford_GT_Logo.png'),
        url: 'https://eventlink.live/GT_Info',
    },
    {
        id: 181,
        name: 'FORD PERFORMANCE CARS',
        image: require('../assets/images/vehicles/2024_Ford_Performance_Mustang_Darkhorse_Silver.png'),
        url: 'https://eventlink.live/Performance_Cars_Info',
    },
    {
        id: 182,
        name: 'FORD PERFORMANCE TRUCKS',
        image: require('../assets/images/vehicles/2023_Ford_PerformanceTrucks_F150_Raptor.png'),
        url: 'https://eventlink.live/Performance_Truck_Info',
    },
    {
        id: 1032,
        name: 'MAVERICK',
        image: require('../assets/images/vehicles/2024_Ford_Maverick_Lariat_Silver.png'),
        url: 'https://eventlink.live/2024_Maverick_Info',
    },
    {
        id: 1030,
        name: 'MUSTANG MACH-E',
        image: require('../assets/images/vehicles/2024_Ford_Mustang_Mach-E_GT_Gray.png'),
        url: 'https://eventlink.live/Mach_E_Info',
    },
    {
        id: 196,
        name: 'MUSTANG',
        image: require('../assets/images/vehicles/2024_Ford_Mustang_GT_Silver.png'),
        url: 'https://eventlink.live/2024_Mustang_Info',
    },
    {
        id: 1031,
        name: 'RANGER',
        image: require('../assets/images/vehicles/2024_Ford_Ranger_Lariat_Silver.png'),
        url: 'https://eventlink.live/2024_Ranger_Brochure',
    },
    {
        id: 1003,
        name: 'SUPER DUTY (F-250)',
        image: require('../assets/images/vehicles/2024_Ford_F250_SuperDuty_Lariat_Silver.png'),
        url: 'https://eventlink.live/Super_Duty_Info',
    },
    {
        id: 190,
        name: 'TRANSIT',
        image: require('../assets/images/vehicles/2023_Ford_Transit_CargoVan_Silver.png'),
        url: 'https://eventlink.live/Transit_Info',
    },
    {
        id: 192,
        name: 'TRANSIT CONNECT WAGON',
        image: require('../assets/images/vehicles/2022_Ford_TransitConnectWagon_Titanium.png'),
        url: 'https://eventlink.live/Transit_Connect_Wagon_Info',
    },
];

export const allLincoln = [
    {
        id: 999,
        name: '2025 AVIATOR',
        image: require('../assets/images/vehicles/2025_Lincoln_Aviator_Reserve.png'),
        url: 'https://eventlink.live/2025_Aviator',
    },
    {
        id: 31,
        name: '2025 NAVIGATOR',
        image: require('../assets/images/vehicles/2025_Lincoln_Navigator_Reserve_GrayMist.png'),
        url: 'https://lincolnevt.com/2025_Navigator',
    },
    {
        id: 1011,
        name: 'AVIATOR',
        image: require('../assets/images/vehicles/2024_Lincoln_Aviator_BlackLabel_Silver.png'),
        url: 'https://eventlink.live/Aviator_Info',
    },
    {
        id: 221,
        name: 'NAUTILUS',
        image: require('../assets/images/vehicles/2025_Lincoln_Nautilus_BlackLabel_Silver.png'),
        url: 'https://eventlink.live/2025_Nautilus',
    },
    {
        id: 168,
        name: 'BLACK LABEL',
        image: require('../assets/images/vehicles/blacklabel_2.png'),
        url: 'https://eventlink.live/Black_Label_Info',
    },
    {
        id: 30,
        name: 'NAVIGATOR',
        image: require('../assets/images/vehicles/2024_Lincoln_Navigator_Premiere_Silver.png'),
        url: 'https://eventlink.live/New_Navigator_Info',
    },
    {
        id: 1029,
        name: 'CORSAIR',
        image: require('../assets/images/vehicles/2025_Lincoln_Corsair_Reserve_Gray.png'),
        url: 'https://eventlink.live/Corsair_Info',
    },
];

export const allVehicles = [...allFord, ...allLincoln];




